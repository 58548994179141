@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* gray */
  --gray-100: #fafafa;
  --gray-200: #f1f1f1;
  --gray-300: #d6d6d6;
  --gray-400: #c4c4c4;
  --gray-500: #b2b2b2;
  --gray-600: #a9a9a9;
  --gray-700: #999999;
  --gray-800: #333533;
  /* yellow */
  --yellow-400: #ffe32b;
  --yellow-700: #ffd100;

  --success-500: #209e00;
  --error-200: #d77373;
  --error-500: #b60000;
  --warning-500: #f79009;

  --transparent: #00000000;
  --white: white;
  --black: #202020;

  --box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  --border: 1px solid var(--gray-300);

  --mobile-breakpoint: 900px;
}

html {
  font-size: 14px;
  overscroll-behavior-y: none;

  @media (-webkit-max-device-pixel-ratio: 1) {
    font-size: 16px;
  }
}

body {
  margin: 0;
  overscroll-behavior-y: none;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 900px) {
    user-select: none;
  }
}

html {
  height: 100vh;
}

body {
  height: 100%;
}

#root {
  position: fixed;
  /* for browser not supporting dvh. transitions doesn't work in this case */
  height: 100%;
  height: 100dvh;
  width: 100%;
  transition: 0.2s;

  >div {
    height: inherit;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 900px) {
  *.desktop-only {
    display: none !important;
  }
}

@media (min-width: 900px) {
  *.mobile-only {
    display: none !important;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  &:focus {
    outline: none;
  }
}

.bullet {
  position: relative;
  padding-left: 0.8em;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    margin-right: 0.3em;
    background-color: currentColor;
  }
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

strong {
  font-weight: 600;
}