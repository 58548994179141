@import "swiper/scss";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

.swiper-button-prev,
.swiper-button-next {
  color: inherit;
  &::after {
    font-weight: 800;
  }
}

.swiper-button-disabled {
  display: none;
}

.swiper-pagination {
  position: unset;
}

@at-root:root {
  --swiper-theme-color: currentColor;
  --swiper-navigation-size: 16px;
  --swiper-pagination-bullet-inactive-color: currentColor;
}
